<template>
  <div
    class="
      question-item
      w-full
      flex flex-col
      justify-start
      items-center
      h-full
      lg:flex-row
      lg:justify-center
      lg:w-8/10
      relative
      z-0
    "
  >
    <div
      class="
        w-full
        h-7/10
        px-5
        flex flex-wrap
        m-0
        p-0
        justify-center
        items-center
        sm:w-7/10
        sm:h-4/10
        z-10
        relative
      "
    >
      <div v-if="value" class="question flex w-full flex-wrap">
        <div
          class="question w-full flex text-center text-primary text-sm"
        ></div>
        <div class="question w-full flex text-center text-primary text-3xl leading-8">
          {{ formDetail.description }}
        </div>
        <div
          class="w-full flex text-justify text-secondary text-1xl px-3 leading-4 mt-1 mb-5"
          v-if="formDetail.help_info"
        >
          {{ formDetail.help_info }}
        </div>
        <div class="w-full flex flex-wrap justify-between">
          <input
            ref="focusForm"
            v-model.trim="value.value[0]"
            @input="
              $emit('input', {
                id: formDetail.id,
                value: [$event.target.value, value.value[1]],
              });
              setWritingTime(500);
            "
            :type="
              validInputTypes.includes(formDetail.form_subtype)
                ? formDetail.form_subtype
                : 'text'
            "
            class="
              w-full
              sm:w-2/3
              mb-2 mb-sm-0
              text-secondary
              px-5
              text-2xl
              md:text-3xl
              border border-primary border-r-0 border-l-0 border-t-0
              sm:mx-3
            "
            :class="[
              !this.$v.value.value.$each[0].required && showErrors
                ? 'invalid'
                : '',
            ]"
            placeholder="Calle"
            v-on:keyup.enter="gotoNext()"
          />
          <input
            v-model.trim="value.value[1]"
            @input="
              $emit('input', {
                id: formDetail.id,
                value: [value.value[0], $event.target.value],
              });
              setWritingTime(500);
            "
            :type="
              validInputTypes.includes(formDetail.form_subtype)
                ? formDetail.form_subtype
                : 'text'
            "
            class="
              w-full
              sm:w-1/3
              mb-2 mb-sm-0
              text-secondary
              px-5
              text-2xl
              md:text-3xl
              border border-primary border-r-0 border-l-0 border-t-0
              sm:mx-3
            "
            :class="[
              !this.$v.value.value.$each[1].required && showErrors
                ? 'invalid'
                : '',
            ]"
            placeholder="Ext. Num."
            v-on:keyup.enter="gotoNext()"
          />
          <!-- <input
            v-model.trim="value.value[2]"
            @input="
              $emit('input', { id: formDetail.id, value: [value.value[0], value.value[1], $event.target.value] });
              setWritingTime(500);
            "
            :type="
              validInputTypes.includes(formDetail.form_subtype)
                ? formDetail.form_subtype
                : 'text'
            "
            class="
              w-1/6
              text-secondary
              px-5
              text-2xl
              md:text-3xl
              border border-primary border-r-0 border-l-0 border-t-0
              sm:mx-3
            "
            :class="[!this.$v.value.value.$each[2].required && showErrors ? 'invalid' : '']"
            placeholder="Int. Num."
            v-on:keyup.enter="gotoNext()"
          /> -->
        </div>
        <transition name="fade-pop">
          <div
            v-if="showErrors == true"
            class="w-full flex flex-wrap justify-end items-center"
          >
            <div
              class="
                w-full
                bg-red-100
                text-red-500
                border-2
                rounded-md
                border-red-400
                px-10
                py-1
                mb-5
                mt-2
              "
              v-for="(error, index) in errors"
              :key="'err-' + index"
            >
              {{ error }}
            </div>
          </div>
        </transition>
      </div>
      <div class="w-full flex justify-around items-center">
        <button
          class="
            py-2
            px-5
            border-2 border-primary
            rounded-md
            text-primary
            hover:bg-primary
            hover:text-white
            transition
            duration-600
            pop
          "
          @click="gotoPrevious()"
        >
          Anterior
        </button>
        <button
          class="
            py-2
            px-5
            border-2 border-primary
            rounded-md
            text-primary
            hover:bg-primary
            hover:text-white
            transition
            duration-600
            pop
          "
          @click="gotoNext()"
        >
          Siguiente
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "QuestionInput",
  data: function () {
    return {
      internValue: null,
      showErrors: false,
      canContinue: true,
      validInputTypes: [
        "email",
        "password",
        "date",
        "text",
        "number",
        "tel",
        "",
      ],
      timeout: null,
    };
  },
  validations() {
    let validations = {
      value: {
        value: {
          required,
          minLength: minLength(2),
          $each: {
            required,
          },
        },
      },
    };

    if (!this.formDetail.is_mandatory) {
      validations.value.value["$each"] = required;
    }

    if (this.formDetail.in_range.length > 0) {
      // If has range values
      // The validations depends of form type and its required values
      if (this.formDetail.value_type == "str") {
        if (["text", "N/A", "email"].includes(this.formDetail.form_subtype)) {
          // Validations if min and max characters
          if (this.formDetail.in_range.length > 0)
            validations.value.value["$each"]["minLength"] = minLength(
              this.formDetail.in_range[0]
            );
          if (this.formDetail.in_range.length > 1)
            validations.value.value["$each"]["maxLength"] = maxLength(
              this.formDetail.in_range[1]
            );
        }
      }
    }
    return validations;
  },
  components: {
  },
  props: {
    formDetail: {
      type: Object,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {},
  },
  computed: {
    errors() {
      let errors = [];
      if (this.showErrors) {
        if (
          "required" in this.$v.value.value.$each[0] &&
          !this.$v.value.value.$each[0].required
        ) {
          errors.push("Calle requerida.");
        }
        if (
          "required" in this.$v.value.value.$each[1] &&
          !this.$v.value.value.$each[1].required
        ) {
          errors.push("Número exterior requerido.");
        }
      }
      return errors;
    },
  },
  mounted() {
    let self = this;
    if (!self.value) {
      self.$emit("input", { id: self.formDetail.id, value: [null, null] });
    }
    self.$nextTick(function () {
      self.$refs["focusForm"].focus();
    });
  },
  methods: {
    setWritingTime(time) {
      let self = this;
      self.isWriting = true;
      clearTimeout(self.timeout);
      self.timeout = setTimeout(() => {
        self.isWriting = false;
      }, time);
    },
    data_ready_w() {
      this.writingAnimation.totalFrames = this.animWriting.totalFrames;
    },
    handleAnimationW: function (anim) {
      let self = this;
      self.animWriting = anim;
      self.animWriting.addEventListener("data_ready", function () {
        self.writingAnimation.totalFrames = self.animWriting.totalFrames;
      });
    },
    data_ready_l() {
      this.listeningAnimation.totalFrames = this.animListening.totalFrames;
    },
    handleAnimationL: function (anim) {
      let self = this;
      self.animListening = anim;
      self.animListening.addEventListener("data_ready", function () {
        self.listeningAnimation.totalFrames = self.animListening.totalFrames;
      });
    },
    gotoPrevious() {
      this.$emit("previous");
    },
    gotoNext() {
      let self = this;
      if (self.required) {
        if (self.isValid()) {
          if (self.value) {
            self.$emit("next");
          } else {
            self.canContinue = false;
          }
        } else {
          // console.log("Parece invlido", this.$v.value.value);
        }
      } else {
        self.$emit("next");
      }
    },
    isValid() {
      let is_valid = false;
      if (this.$v.value.value.$invalid) {
        this.showErrors = true;
      } else {
        is_valid = true;
        this.showErrors = false;
      }
      return is_valid;
    },
  },
};
</script>

<style>
input {
  outline: none;
}
</style>
