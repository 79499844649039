var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"\n    question-item\n    w-full\n    flex flex-col\n    justify-start\n    items-center\n    h-full\n    lg:flex-row\n    lg:justify-center\n    lg:w-8/10\n    relative\n    z-0\n  "},[_c('div',{staticClass:"\n      w-full\n      h-7/10\n      px-5\n      flex flex-wrap\n      m-0\n      p-0\n      justify-center\n      items-center\n      sm:w-7/10\n      sm:h-4/10\n      z-10\n      relative\n    "},[(_vm.value)?_c('div',{staticClass:"question flex w-full flex-wrap"},[_c('div',{staticClass:"question w-full flex text-center text-primary text-sm"}),_vm._v(" "),_c('div',{staticClass:"question w-full flex text-center text-primary text-3xl leading-8"},[_vm._v("\n        "+_vm._s(_vm.formDetail.description)+"\n      ")]),_vm._v(" "),(_vm.formDetail.help_info)?_c('div',{staticClass:"w-full flex text-justify text-secondary text-1xl px-3 leading-4 mt-1 mb-5"},[_vm._v("\n        "+_vm._s(_vm.formDetail.help_info)+"\n      ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-full flex flex-wrap justify-between"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.value.value[0]),expression:"value.value[0]",modifiers:{"trim":true}}],ref:"focusForm",staticClass:"\n            w-full\n            sm:w-2/3\n            mb-2 mb-sm-0\n            text-secondary\n            px-5\n            text-2xl\n            md:text-3xl\n            border border-primary border-r-0 border-l-0 border-t-0\n            sm:mx-3\n          ",class:[
            !this.$v.value.value.$each[0].required && _vm.showErrors
              ? 'invalid'
              : '' ],attrs:{"type":_vm.validInputTypes.includes(_vm.formDetail.form_subtype)
              ? _vm.formDetail.form_subtype
              : 'text',"placeholder":"Calle"},domProps:{"value":(_vm.value.value[0])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.value, 0, $event.target.value.trim())},function($event){_vm.$emit('input', {
              id: _vm.formDetail.id,
              value: [$event.target.value, _vm.value.value[1]],
            });
            _vm.setWritingTime(500);}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.gotoNext()},"blur":function($event){return _vm.$forceUpdate()}}}),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.value.value[1]),expression:"value.value[1]",modifiers:{"trim":true}}],staticClass:"\n            w-full\n            sm:w-1/3\n            mb-2 mb-sm-0\n            text-secondary\n            px-5\n            text-2xl\n            md:text-3xl\n            border border-primary border-r-0 border-l-0 border-t-0\n            sm:mx-3\n          ",class:[
            !this.$v.value.value.$each[1].required && _vm.showErrors
              ? 'invalid'
              : '' ],attrs:{"type":_vm.validInputTypes.includes(_vm.formDetail.form_subtype)
              ? _vm.formDetail.form_subtype
              : 'text',"placeholder":"Ext. Num."},domProps:{"value":(_vm.value.value[1])},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.value.value, 1, $event.target.value.trim())},function($event){_vm.$emit('input', {
              id: _vm.formDetail.id,
              value: [_vm.value.value[0], $event.target.value],
            });
            _vm.setWritingTime(500);}],"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.gotoNext()},"blur":function($event){return _vm.$forceUpdate()}}})]),_vm._v(" "),_c('transition',{attrs:{"name":"fade-pop"}},[(_vm.showErrors == true)?_c('div',{staticClass:"w-full flex flex-wrap justify-end items-center"},_vm._l((_vm.errors),function(error,index){return _c('div',{key:'err-' + index,staticClass:"\n              w-full\n              bg-red-100\n              text-red-500\n              border-2\n              rounded-md\n              border-red-400\n              px-10\n              py-1\n              mb-5\n              mt-2\n            "},[_vm._v("\n            "+_vm._s(error)+"\n          ")])}),0):_vm._e()])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-full flex justify-around items-center"},[_c('button',{staticClass:"\n          py-2\n          px-5\n          border-2 border-primary\n          rounded-md\n          text-primary\n          hover:bg-primary\n          hover:text-white\n          transition\n          duration-600\n          pop\n        ",on:{"click":function($event){return _vm.gotoPrevious()}}},[_vm._v("\n        Anterior\n      ")]),_vm._v(" "),_c('button',{staticClass:"\n          py-2\n          px-5\n          border-2 border-primary\n          rounded-md\n          text-primary\n          hover:bg-primary\n          hover:text-white\n          transition\n          duration-600\n          pop\n        ",on:{"click":function($event){return _vm.gotoNext()}}},[_vm._v("\n        Siguiente\n      ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }