<template>
  <div
    class="
      question-item
      w-full
      flex flex-col
      justify-start
      items-center
      h-full
      lg:flex-row
      lg:justify-center
      lg:w-8/10
      relative
      z-0
    "
  >

    <div
      class="
        w-full
        h-7/10
        px-5
        flex flex-wrap
        m-0
        p-0
        justify-center
        items-center
        sm:w-7/10
        sm:h-4/10
        z-10
        relative
      "
    >
      <div class="question flex w-full flex-wrap">
        <div
          class="question w-full flex text-center text-primary text-3xl leading-8"
          data-aos="fade-left"
          data-aos-duration="500"
        >
          {{ formDetail.description }}
        </div>
        <div
          class="w-full flex text-justify text-secondary text-1xl px-3 leading-4 mt-1 mb-5"
          v-if="formDetail.help_info"
        >
          {{ formDetail.help_info }}
        </div>
        <div class="w-full flex flex-wrap justify-center sm:mb-10 datePickerRange">
          <t-datepicker
            v-model="selectedRangeDate"
            :minDate="new Date()"
            :required="true"
            :range="true"
            :date-formatter="dateFormatter"
            :date-parser="dateParser"
            date-format="YYYY-MM-DD"
            user-format="YYYY-MM-DD"
            autoClose='true'
            id=""
            class="border-2 border-primary rounded-md text-primary text-xl bg-purple-50 w-72 input_dateRange"
            @input="
              $emit('input', { id: formDetail.id, value: selectedRangeDate });
              setWritingTime(500);
            "
          />
        </div>
        <transition name="fade-pop">
          <div
            v-if="showErrors == true"
            class="w-full flex flex-wrap justify-end items-center"
          >
            <div
              class="
                w-full
                bg-red-100
                text-red-500
                border-2
                rounded-md
                border-red-400
                px-10
                py-1
                mb-5
                mt-2
              "
              v-for="(error, index) in errors"
              :key="'err-' + index"
            >
              {{ error }}
            </div>
          </div>
        </transition>
        <transition name="fade-pop">
          <div
            v-if="canContinue == false"
            class="w-full flex justify-end items-center"
          >
            <div
              class="
                bg-blue-100
                text-blue-500
                border-2
                rounded-md
                border-blue-400
                px-10
                py-1
                mb-5
                mt-2
              "
            >
              ¡Ups, necesito saber esta información para continuar.!
            </div>
          </div>
        </transition>
      </div>
      <div class="w-full flex justify-around items-center">
        <button
          class="
            py-2
            px-5
            border-2 border-primary
            rounded-md
            text-primary
            hover:bg-primary
            hover:text-white
            transition
            duration-600
            pop
          "
          @click="gotoPrevious()"
        >
          Anterior
        </button>
        <button
          class="
            py-2
            px-5
            border-2 border-primary
            rounded-md
            text-primary
            hover:bg-primary
            hover:text-white
            transition
            duration-600
            pop
          "
          @click="gotoNext()"
        >
          Siguiente
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: "QuestionDate",
  data: function () {
    return {
      showErrors: false,
      canContinue: true,
      selectedRangeDate: [],
      timeout: null,
    };
  },
  components: {

  },
  props: {
    formDetail: {
      type: Object,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {},
  },
  computed: {
    errors(){
      let errors = [];
      if(this.showErrors){
        errors.push("¡Ups!, sólo se permiten viajes con un mínimo 3 días.");
      }
      return errors;
    }
  },
  mounted() {
    let startdt = moment(new Date());
    let enddt = moment().add(3, 'days');
    this.selectedRangeDate = [startdt, enddt];
  },
  methods: {
    dateFormatter (date, format) {
      return moment(date).format(format)
    },
    dateParser (date, format) {
      return moment(date, format).toDate()
    },
    setWritingTime(time) {
      let self = this;
      self.isWriting = true;
      clearTimeout(self.timeout);
      self.timeout = setTimeout(() => {
        self.isWriting = false;
      }, time);
    },
    data_ready_w() {
      this.writingAnimation.totalFrames = this.animWriting.totalFrames;
    },
    handleAnimationW: function (anim) {
      let self = this;
      self.animWriting = anim;
      self.animWriting.addEventListener("data_ready", function () {
        self.writingAnimation.totalFrames = self.animWriting.totalFrames;
      });
    },
    data_ready_l() {
      this.listeningAnimation.totalFrames = this.animListening.totalFrames;
    },
    handleAnimationL: function (anim) {
      let self = this;
      self.animListening = anim;
      self.animListening.addEventListener("data_ready", function () {
        self.listeningAnimation.totalFrames = self.animListening.totalFrames;
      });
    },

    gotoPrevious() {
      this.$emit("previous", this.question);
    },
    gotoNext() {
      let self = this;
      if (self.required) {
        if (self.selectedRangeDate.length == 2) {

          let start_date = moment(self.selectedRangeDate[0]);
          let return_date = moment(self.selectedRangeDate[1]);
          let travel_days = return_date.diff(start_date, "days");

          if(travel_days > 2){
            self.$emit("next");
          } else {
            self.showErrors = true;
          }
        } else {
          self.canContinue = false;
        }
      } else {
        self.$emit("next");
      }
    },
  },
};
</script>

<style >
.mbsc-btn-e > div {
  color: transparent !important;
}
.mbsc-sc-itm > div {
  color: transparent !important;
}
.mbsc-mobiscroll .mbsc-fr-w {
  background: transparent;
  color: #9ac452;
}

.rotateY-180 {
  transform: rotateY(180deg);
}
.datePickerRange .text-gray-600{
  visibility: hidden;
}


</style>