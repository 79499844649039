<template>
  <div
    class="
      question-item
      w-full
      flex flex-col
      justify-start
      items-center
      h-full
      lg:flex-row
      lg:justify-center
      lg:w-8/10
      relative
      z-0
    "
  >
    <div
      class="
        w-full
        h-7/10
        px-5
        flex flex-wrap
        m-0
        p-0
        justify-center
        items-center
        sm:w-7/10
        sm:h-4/10
        z-10
        relative
      "
    >
      <div v-if="value" class="question flex w-full flex-wrap">
        <div
          class="question w-full flex text-center text-primary text-sm"
        ></div>
        <div class="question w-full flex text-center text-primary text-3xl leading-8">
          {{ formDetail.description }}
        </div>
        <div
          class="w-full flex text-justify text-secondary text-1xl px-3 leading-4 mt-1 mb-5"
          v-if="formDetail.help_info"
        >
          {{ formDetail.help_info }}
        </div>
        <div class="w-full flex flex-wrap justify-center">
          <span
            v-for="(item, index) in value.value"
            :key="index"
            class="
              flex
              justify-center
              items-center
              m-1
              font-medium
              py-0
              px-3
              bg-white
              rounded-full
              text-white
              bg-secondary
              border border-primar
            "
          >
            <span
              class="
                text-xl
                font-normal
                leading-none
                max-w-full
                flex-initial
                whitespace-nowrap
              "
              >{{ item }} años</span
            >
            <div class="flex flex-auto flex-row-reverse ml-1">
              <font-awesome-icon
                :icon="['fas', 'times']"
                class="
                  fa-lg
                  text-primary
                  cursor-pointer
                  transform
                  duration-300
                  hover:scale-110
                "
                @click="removeAge(index)"
              />
            </div>
          </span>
          <div class="w-full flex justify-center">
            <input
              ref="focusForm"
              v-model="auxInputValue"
              :type="
                validInputTypes.includes(formDetail.form_subtype)
                  ? formDetail.form_subtype
                  : 'text'
              "
              class="
                w-full
                sm:w-1/3
                text-secondary
                px-5
                text-3xl
                border border-primary border-r-0 border-l-0 border-t-0
                mx-1
              "
              :class="[]"
              placeholder="Edad"
              v-on:keyup.enter="addAge(auxInputValue)"
            />
            <div class="h-full text-center flex items-center px-5 rounded-r-md bg-primary text-white" @click="addAge(auxInputValue)">+</div>
          </div>
        </div>
        <transition name="fade-pop">
          <div
            v-if="showErrors == true"
            class="w-full flex flex-wrap justify-end items-center"
          >
            <div
              class="
                w-full
                bg-red-100
                text-red-500
                border-2
                rounded-md
                border-red-400
                px-10
                py-1
                mb-5
                mt-2
              "
              v-for="(error, index) in errors"
              :key="'err-' + index"
            >
              {{ error }}
            </div>
          </div>
        </transition>
      </div>
      <div class="w-full flex justify-around items-center">
        <button
          class="
            py-2
            px-5
            border-2 border-primary
            rounded-md
            text-primary
            hover:bg-primary
            hover:text-white
            transition
            duration-600
            pop
          "
          @click="gotoPrevious()"
        >
          Anterior
        </button>
        <button
          class="
            py-2
            px-5
            border-2 border-primary
            rounded-md
            text-primary
            hover:bg-primary
            hover:text-white
            transition
            duration-600
            pop
          "
          @click="gotoNext()"
        >
          Siguiente
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, maxLength } from "vuelidate/lib/validators";

export default {
  name: "ArrayInput",
  data: function () {
    return {
      internValue: [],
      auxInputValue: "",
      showErrors: false,
      canContinue: true,
      validInputTypes: [
        "email",
        "password",
        "date",
        "text",
        "number",
        "tel",
        "",
      ],
      timeout: null,
    };
  },
  validations() {
    let validations = {
      value: {
        value: {
          required,
          minLength: minLength(1),
          $each: {
            required,
          },
        },
      },
    };

    if (this.formDetail.in_range.length > 0) {
      // If has range values
      // The validations depends of form type and its required values
      if (this.formDetail.value_type == "str") {
        if (["text", "N/A", "email"].includes(this.formDetail.form_subtype)) {
          // Validations if min and max characters
          if (this.formDetail.in_range.length > 0)
            validations.value.value["$each"]["minLength"] = minLength(
              this.formDetail.in_range[0]
            );
          if (this.formDetail.in_range.length > 1)
            validations.value.value["$each"]["maxLength"] = maxLength(
              this.formDetail.in_range[1]
            );
        }
      }
    }
    return validations;
  },
  components: {

  },
  props: {
    formDetail: {
      type: Object,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    value: {},
  },
  computed: {
    errors() {
      let errors = [];
      if (this.showErrors) {
        if (!this.$v.value.value.minLength) {
          errors.push("Debe ingresar al menos la edad de una persona.");
        }
        if (!this.$v.value.value.required) {
          errors.push("Esta información es necesaria.");
        }
      }
      return errors;
    },
  },
  mounted() {
    let self = this;
    if (
      !self.value ||
      (self.value &&
        !self.value.value)
    ) {
      self.$emit("input", {
        id: self.formDetail.id,
        value: [],
      });
    } else {
      self.internValue = self.value.value
    }
          // console.log(err);
    self.$nextTick(function () {
      self.$refs["focusForm"].focus();
    });
  },
  methods: {
    addAge(value) {
      if (value) {
        this.internValue.push(value);
        this.$emit('input', { id: this.formDetail.id, value: this.internValue });
        this.auxInputValue = '';
        this.setWritingTime(500);        
      }
    },
    removeAge(index) {
      this.internValue.splice(index, 1)
      this.$emit('input', { id: this.formDetail.id, value: this.internValue });
    },
    setWritingTime(time) {
      let self = this;
      self.isWriting = true;
      clearTimeout(self.timeout);
      self.timeout = setTimeout(() => {
        self.isWriting = false;
      }, time);
    },
    data_ready_w() {
      this.writingAnimation.totalFrames = this.animWriting.totalFrames;
    },
    handleAnimationW: function (anim) {
      let self = this;
      self.animWriting = anim;
      self.animWriting.addEventListener("data_ready", function () {
        self.writingAnimation.totalFrames = self.animWriting.totalFrames;
      });
    },
    data_ready_l() {
      this.listeningAnimation.totalFrames = this.animListening.totalFrames;
    },
    handleAnimationL: function (anim) {
      let self = this;
      self.animListening = anim;
      self.animListening.addEventListener("data_ready", function () {
        self.listeningAnimation.totalFrames = self.animListening.totalFrames;
      });
    },
    gotoPrevious() {
      this.$emit("previous");
    },
    gotoNext() {
      let self = this;
      if (self.required) {
        if (self.isValid()) {
          if (self.value) {
            self.$emit("next");
          } else {
            self.canContinue = false;
          }
        }
      } else {
        self.$emit("next");
      }
    },
    isValid() {
      let is_valid = false;
      if (this.$v.value.value.$invalid) {
        this.showErrors = true;
      } else {
        is_valid = true;
        this.showErrors = false;
      }
      return is_valid;
    },
  },
};
</script>

<style>
input {
  outline: none;
}
</style>
